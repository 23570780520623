    <avv-modal type="danger">
        <div class=" modal-container">
            <img [src]="urlAssets + '/common/icons/modals/Seguridad.svg'" alt="">
            <h1 class="modal-container__title">{{'txt_title_error_technical' | maptextsbylang:{lang:'es'} }}</h1>
            <p class="modal-container__text">{{'txt_text_error_technical' | maptextsbylang: {lang:'es'} }}</p>
        </div>
            <button avv-dialog-btn-continue class="avv-btn-primary" (click)="closeModal()">{{'txt_button_assert' | maptextsbylang: {lang: 'es'} }}</button>
    </avv-modal>



