import { Routes } from "@angular/router";
import { EmptyRouteComponent } from "./empty-route/empty-route.component";
import { configtextsResolver } from "./core/resolvers/configtexts.resolver";
import { coverageCitiesResolver } from "./core/resolvers/coverage/coverage-cities.resolver";
import { authGuard } from "./core/guards/auth/auth.guard";
import { AppComponent } from "./app.component";

export const routes: Routes = [
  {
    path: "",
    component: AppComponent,
    children: [
      {
        path: "",
        loadChildren: () =>
          import("../app/features/routes/offer-product.routes").then(
            (r) => r.OFFER_PRODUCT_ROUTES,
          ),
        resolve: {
          configTexts: configtextsResolver,
        },
      },
      {
        path: "elegir-seguro",
        loadChildren: () =>
          import("../app/features/routes/sure-product.routes").then(
            (r) => r.SURE_PRODUCT_ROUTES,
          ),
        canActivate: [authGuard],
        data: { preload: true },
        resolve: {
          configTexts: configtextsResolver,
        },
      },
      {
        path: "otp",
        loadChildren: () =>
          import("../app/features/routes/otp.routes").then((r) => r.OTP_ROUTES),
        canActivate: [authGuard],
        data: { preload: true },
        resolve: {
          configTexts: configtextsResolver,
        },
      },
      {
        path: "medios-manejo",
        loadChildren: () =>
          import("../app/features/routes/management-means.routes").then(
            (r) => r.MANAGEMENT_MEANS_ROUTES,
          ),
        canActivate: [authGuard],
        data: { preload: true },
        resolve: {
          configTexts: configtextsResolver,
          coverageCities: coverageCitiesResolver,
        },
      },
      {
        path: "tips",
        loadChildren: () =>
          import("../app/features/routes/tips.routes").then(
            (r) => r.TIPS_ROUTES,
          ),
        canActivate: [authGuard],
        data: { preload: true },
        resolve: {
          configTexts: configtextsResolver,
        },
      },
      {
        path: "resumen",
        loadChildren: () =>
          import("../app/features/routes/summary.routes").then(
            (r) => r.SUMMARY_ROUTES,
          ),
        canActivate: [authGuard],
        data: { preload: true },
        resolve: {
          configTexts: configtextsResolver,
        },
      },
      {
        path: "datos-trabajo",
        loadChildren: () =>
          import("../app/features/routes/work-data.routes").then(
            (r) => r.WORK_DATA_ROUTES,
          ),
        canActivate: [authGuard],
        data: { preload: true },
        resolve: {
          configTexts: configtextsResolver,
        },
      },
      {
        path: "**",
        component: EmptyRouteComponent,
      },
    ],
  },
];
