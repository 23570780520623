import { ApplicationConfig, importProvidersFrom } from "@angular/core";
import { provideRouter } from "@angular/router";
import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { getSingleSpaExtraProviders } from "single-spa-angular";
import { APP_BASE_HREF } from "@angular/common";
import { routes } from "./app.routes";
import { loaderInterceptor } from "./core/interceptors/loader/loader.interceptor";
import { headersInterceptor } from "./core/interceptors/headers/headers.interceptor";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MODULE_FEDERATION } from "./module-federation/injection.token";

export const appConfig: ApplicationConfig = {
  providers: [
    getSingleSpaExtraProviders(),
    provideRouter(routes),
    importProvidersFrom([BrowserAnimationsModule]),
    provideHttpClient(
      withInterceptors([loaderInterceptor, headersInterceptor]),
    ),
    {
      provide: APP_BASE_HREF,
      useValue: "/bancadigital/abrir-cuenta-ahorro",
    },
    {
      provide: MODULE_FEDERATION,
      useValue: false,
    },
  ],
};
