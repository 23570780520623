import { inject } from "@angular/core";
import { CanActivateFn } from "@angular/router";
import { NavigateService } from "../../../../app/shared/services/navigate.service";
import {
  Routes,
  routesGuardInfoCDA,
} from "../../../../app/shared/utils/enums/routes.enum";
import { DataCdaService } from "../../services/cda/data-cda.service";
import { UserDataService } from "../../services/user-data/user-data.service";

export const authGuard: CanActivateFn = (route, state) => {
  if (routesGuardInfoCDA.some((r) => r === route.url[0].path)) {
    return validateInfoOpenCDA();
  } else {
    return validateAuthData();
  }
};

const validateInfoOpenCDA = () => {
  const infoCDA = inject(DataCdaService);
  if (infoCDA.build().accountSelected) {
    return true;
  } else {
    return navigateHome();
  }
};

const validateAuthData = () => {
  const userDataService = inject(UserDataService);
  if (userDataService.customerData.accessToken && validateInfoOpenCDA()) {
    return true;
  } else {
    return navigateHome();
  }
};

const navigateHome = () => {
  const navigateService = inject(NavigateService);
  navigateService.navigate(Routes.OfferProduct);
  return false;
};
